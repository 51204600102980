﻿angular.module("orderInventory").controller("orderInventoryController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {

    $scope.samples = [];
    $scope.loading = true;
    $scope.sizeOptions = ["Select"];
    $scope.colorOptions = ["Select"];
    $scope.seriesOptions = ["Select"];
    $scope.plantOptions = ["Select"];

    $scope.nameSuggestions = [];
    $scope.factorySuggestions = [];
    $scope.numberSuggestions = [];
    $scope.request = {
        selectedQuantity: 1

    };
    $scope.current =
        {
        timeStamp:"",
        totalResults: 0,
        totalPages: 1,
        ItemsPerPage: 30
        }
    $scope.timeStamp = "";
    $scope.totalResults = 0;
    $scope.totalPages = 1;
    $scope.maxSize = 3;

    $scope.filters = {
        ProductName: '',
        ProductNumber: '',
        Size: '',
        Color: '',
        Series: '',
        Plant: '',
        PageSize: 30,
        PageNumber: 1,
        SortField: 'Description',
        SortOrder: 0,
        Format: 'json'
    };

    $scope.updateSort = function (fieldName) {
        if ($scope.filters.SortField == fieldName) {
            if ($scope.filters.SortOrder == 0) {
                $scope.filters.SortOrder = 1;
            } else {
                $scope.filters.SortOrder = 0;
            }
        } else {
            $scope.filters.SortField = fieldName
            $scope.filters.SortOrder = 0;
        }
        $scope.filters.PageNumber = 1;
        $('#resultstable th').removeClass('sort');
        $('#resultstable th').removeClass('sortasc');
        $('#resultstable th').removeClass('sortdesc');
        var sortHeader = $('#resultstable th#hdr_' + $scope.filters.SortField)
        sortHeader.addClass('sort');
        if ($scope.filters.SortOrder == 0) {
            sortHeader.addClass('sortasc');
        } else {
            sortHeader.addClass('sortdesc');
        }
        $scope.doSearch();
    };

    $scope.doExport = function (preservePage) {
        // Clear any suggestions lists
        $scope.nameSuggestions = [];
        $scope.numberSuggestions = [];
        $scope.factorySuggestions = [];
        $scope.loading = true;       
        // Reset to first page as needed
        $scope.filters.PageNumber = 1;
        $scope.filters.Format = 'csv';        
        window.open('/InventoryOrdering/GetInventoryCSV?' + params);               
        $scope.loading = false;
        $scope.filters.Format = 'json';
    };    

    $scope.addSample = function (rowItem) {
        var product = rowItem.$parent.item;
        var sampleOrderForm = $('#sampleOrder');
        var dateNeeded = new Date();
        dateNeeded.setDate(dateNeeded.getDate() + 14);
        var requestData = {
                ProductName: product.Name,
                Plant: product.Dispatch_Factory,
                RequestedQty: 0,
                ApproximateJobQty: 0,                
                JobName: "Inventory Sample Order",
                DateNeeded: (dateNeeded.getMonth() + 1) + '/' + dateNeeded.getDate() + '/' + dateNeeded.getFullYear(),
                Color: product.Color,
                Size: product.Size,
                SampleType: "Box Sample",
                Comments: "Product Number:" + product.Item_number + "  Lot Number:" + product.Lot_Number + " Size:" + product.Size
        };
       
        $.ajax({
            type: 'POST',
            url: '/SampleOrdering/AddCustomCartItem',
            data: requestData,
            success: function (data) {
                window.location.href = '/SampleOrdering/Cart';
            },
            statusCode: {
                404: function (content) { $('#cart_message').addClass('error-message'); $('#cart_message').text("Error - the Custom Sample you were trying to add was not successful!"); },
                500: function (content) { $('#cart_message').addClass('error-message'); $('#cart_message').text("Error - Internal Server Error!"); }
            },
            error: function (req, status, errorObj) {
                $('#cart_message').addClass('error-message');
                $('#cart_message').text("Error - the Custom Sample you were trying to add was not successful!");
            }
        });

         
    }

    $scope.addToCartFromDetail = function (e) {
            
        if (typeof $scope.request.selectedQuantity == 'undefined' || $scope.request.selectedQuantity < 1) {
                e.preventDefault();
                //var link = $('#addToQuote');
                //oldLink = link.attr('href');
                //var newQuantity = $scope.request.selectedQuantity;
                // var newLink = oldLink.replace(/(\/InventoryOrdering\/AddToCart)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/.*/g, "$1/$2/$3/$4/" + newQuantity);            
            } else {
                
            }
        
    }


    $scope.updateQuantity = function (preservePage) {
       var link = $('#addToQuote');
        link.attr("disabled", "disabled");        
        //oldLink = link.attr('href');
        var newQuantity = $scope.request.selectedQuantity;
        if (newQuantity > 0) {
            //var newLink = oldLink.replace(/(\/InventoryOrdering\/AddToCart)\/([^\/]*)\/([^\/]*)\/([^\/]*)\/.*/g, "$1/$2/$3/$4/" + newQuantity);
            //link.attr('href', newLink);
            link.removeAttr('disabled');
        }
    }

    $scope.doSearch = function (preservePage) {
        // Clear any suggestions lists
        $scope.nameSuggestions = [];
        $scope.numberSuggestions = [];
        $scope.factorySuggestions = [];
        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;

        
        $http.get('/InventoryOrdering/GetInventory', {
           params: $scope.filters
        }).then(function (result) {
            $scope.loading = false;
            var dat = result.data;
            $scope.lineOptions = dat.Lines;
            $scope.ProductName = dat.ProductName;
            $scope.ProductNumber = dat.ProductNumber;                
            $scope.sizeOptions = dat.Sizes;
            $scope.colorOptions = dat.Colors;
            $scope.groupOptions = dat.Groups;
            $scope.Inventory = dat.InventoryList;
            $scope.totalPages = dat.TotalPages;
            $scope.maxQuantity = dat.MaxQuantity;
            $scope.dispatchFactoryOptions = dat.DispatchFactory;
            $scope.timeStamp = dat.timeStamp;     
            $scope.current.timeStamp= dat.timeStamp;     
            $scope.totalResults = dat.TotalResults;
            $scope.current.totalResults = dat.TotalResults;
                
            var oldPageNumber = $scope.filters.PageNumber;
            $scope.filters.Format = 'csv';
            var params = $.param($scope.filters);
            $scope.filters.PageNumber = oldPageNumber;
            $('#exportCSV').attr("href", "/InventoryOrdering/GetInventory?" + params);
            $scope.filters.Format = 'json'; 

            if ($scope.totalResults == 0) {
                var keepSpecial;
                if ($scope.filters.productSpecial == true){
                    keepSpecial = true;
                }
                    

                $scope.filters = {
                    ProductName: '',
                    ProductNumber: '',
                    Size: '',
                    Color: '',
                    Series: '',
                    Plant: '',
                    PageSize: 30,
                    PageNumber: 1,
                    SortField: 'Description',
                    SortOrder: 0,
                    Format: 'json'
                };

                if (keepSpecial == true) {
                    $scope.filters.productSpecial = true;
                }
                    

                $cookies.put('oicdispatchFactory', $scope.filters.dispatchFactory);
                $cookies.put('oicProductGroup', $scope.filters.ProductGroup);
                $cookies.put('oicProductName', $scope.filters.ProductName);
                $cookies.put('oicProductNumber', $scope.filters.ProductNumber);
                $cookies.put('oicProductLine', $scope.filters.ProductLine);
                $cookies.put('oicProductSize', $scope.filters.ProductSize);
                $cookies.put('oicProductColor', $scope.filters.ProductColor);
                $cookies.put('oicproductQuantity', $scope.filters.productQuantity);
                $cookies.put('oicproductSpecial', $scope.filters.productSpecial);

                    
                $scope.doSearch();
            }

        });


        $cookies.put('oicdispatchFactory', $scope.filters.dispatchFactory);
        $cookies.put('oicProductGroup', $scope.filters.ProductGroup);
        $cookies.put('oicProductName', $scope.filters.ProductName);
        $cookies.put('oicProductNumber', $scope.filters.ProductNumber);
        $cookies.put('oicProductLine', $scope.filters.ProductLine);
        $cookies.put('oicProductSize', $scope.filters.ProductSize);
        $cookies.put('oicProductColor', $scope.filters.ProductColor);
        $cookies.put('oicproductQuantity', $scope.filters.productQuantity);
        $cookies.put('oicproductSpecial', $scope.filters.productSpecial);

    };

    $scope.init = function () {
        $(document).ready(function () {
            var resultsTableElem = document.getElementById("resultstable");
            var resultsTableMOConfig = { attributes: false, childList: true, subtree: true };
            var resultsTableMOCallback = (mutationList, observer) => {
                $(".price-tooltip").tooltip();
            };
            var observer = new MutationObserver(resultsTableMOCallback);
            observer.observe(resultsTableElem, resultsTableMOConfig);
        });
    };

    $scope.getNameSuggestions = function (kw) {
        if (kw.length > 2) {
            var dat = $scope.filters;
            dat.ProductName = kw;    
            $http.get('/InventoryOrdering/GetNameSuggestions', {
                params: dat,
                cache: true
            }).then(function (response) {
                $scope.nameSuggestions = response.data;
            });
        }
    };
    
    $scope.getNumberSuggestions = function (kw) {
        if (kw.length > 2) {
            var dat = $scope.filters;
            dat.ProductNumber = kw;
            $http.get('/InventoryOrdering/GetNumberSuggestions', {
                params: dat,
                cache: true
            }).then(function (response) {
                $scope.numberSuggestions = response.data;
            });
        }
    };

    $scope.changePage = function () {
        if (!$scope.loading) {
            $scope.doSearch(true);
        }
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i == 1 || i == numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }
        }
        return pages;
    }

    
    $scope.clearSearch = function () {
        $scope.filters = {
            ProductName: '',
            ProductNumber: '',
            Size: '',
            Color: '',
            Series: '',
            Plant: '',
            PageSize: 30,
            PageNumber: 1,
            SortField: 'Description',
            SortOrder: 0
        };
        $scope.doSearch();
    };


    //cookie code
    $scope.setInitialFilter = function () {

        if (angular.isDefined($cookies.get('oicdispatchFactory'))) {
            $scope.filters.dispatchFactory = $cookies.get('oicdispatchFactory');
        }
        if (angular.isDefined($cookies.get('oicProductGroup'))) {
            $scope.filters.ProductGroup = $cookies.get('oicProductGroup');
        }
        if (angular.isDefined($cookies.get('oicProductName'))) {
            $scope.filters.ProductName = $cookies.get('oicProductName');
        }
        if (angular.isDefined($cookies.get('oicProductNumber'))) {
            $scope.filters.ProductNumber = $cookies.get('oicProductNumber');
        }
        if (angular.isDefined($cookies.get('oicProductLine'))) {
            $scope.filters.ProductLine = $cookies.get('oicProductLine');
        }
        if (angular.isDefined($cookies.get('oicProductSize'))) {
            $scope.filters.ProductSize = $cookies.get('oicProductSize');
        }
        if (angular.isDefined($cookies.get('oicProductColor'))) {
            $scope.filters.ProductColor = $cookies.get('oicProductColor');
        }
        if (angular.isDefined($cookies.get('oicproductQuantity'))) {
            $scope.filters.productQuantity = $cookies.get('oicproductQuantity');
        }
        if (angular.isDefined($cookies.get('oicproductSpecial'))) {

            $scope.specialCustomTF;
            if ($cookies.get('oicproductSpecial') == "true") {
                $scope.specialCustomTF = true;
            }
            else {
                $scope.specialCustomTF = false;
            }
            $scope.filters.productSpecial = $scope.specialCustomTF;
            
        }
    };

    $scope.setInitialFilter();
    $scope.doSearch();
}])
.directive('setHref', function () {
    return {
        restrict: 'A',
        scope: {
            item: '='
        },
        link: function (scope, element, attrs) {
            var csrModel = {
                ProductName: scope.item.Description,
                Size: scope.item.Size,
                Plant: scope.item.Dispatch_Factory_Description
            };
            var csrModelJSON = JSON.stringify(csrModel);
            var csrModelB64Encoded = btoa(csrModelJSON);
            attrs.$set('href', '/SampleOrdering/Cart?SampleData=' + csrModelB64Encoded + '#AddCustomSample');
        }
    };
});

