﻿angular.module("orderSamples").controller("orderSamplesHistoryController",
    ["$scope", "$http", "$uibModal", "$filter", function ($scope, $http, $uibModal, $filter, u) {

        // Filters for the page. 
        $scope.filters = {
            EntityId: null,
            UserName: null,
            SortCol: "OrderNo",
            SortDir: "D",
            Page: 1
        };

        //This is set for the current user
        $scope.user = window.UserName;

        //Loading statuses        
        $scope.isLoading = true;
        $scope.loadingAccount = true;
        $scope.loadingOrderedBy = true;

        // Order History Items
        $scope.OrderHistoryItems = [];
        // Customer Account Lists
        $scope.CustomerAccounts = [];
        // the OrderedBy (account on behalf of)
        $scope.OrderedBy = [];

        // Current values.  
        $scope.current = {
            Account: -1,
            OrderedBy: null,
            Page: 1,
            ItemCount: 0
        };

        $scope.maxSize = 5;

        // This should load up the filters.  
        $scope.getFilters = function () {
            if (typeof sessionStorage.orderHistoryFilters != 'undefined') {
                $scope.filters = JSON.parse(sessionStorage.orderHistoryFilters);
            } else {
                $scope.filters = {
                    EntityId: -1,
                    UserName: window.UserName,
                    SortCol: "OrderNo",
                    SortDir: "D",
                    Page: 1
                };
            }
        };

        // update the history AND save the filters
        $scope.$watch('filters', function () {
            //Clean up all of the sort visible classes
            $('#resultstable th').removeClass('sort');
            $('#resultstable th').removeClass('sortasc');
            $('#resultstable th').removeClass('sortdesc');
            // Format the header classes

            if ($scope.isLoading && ($scope.CustomerAccounts.length < 1 || $scope.OrderedBy.length < 1)) {
                $scope.getAccounts();
                $scope.getOrderedBy();
            } else {
                $scope.getHistory();
                sessionStorage.orderHistoryFilters = JSON.stringify($scope.filters);
            }
        }, true);

        $scope.$watch('[loadingAccount,loadingOrderedBy]', function () {
            console.log($scope.loadingAccount);
            console.log($scope.loadingOrderedBy);
            $scope.isLoading = $scope.loadingAccount; // || $scope.loadingOrderedBy;
            if (!$scope.isLoading) {
                $scope.getHistory();
            }
            //console.log($scope.isLoading);
        }, true);

        // This fetches the Accounts
        $scope.getAccounts = function () {
            $scope.loadingAccount = true;
            $http.post('/SampleOrdering/OrderHistory/getAccounts', $scope.filters).then(function (result) {
                $scope.CustomerAccounts = result.data;
            });

        };

        $scope.$watch('CustomerAccounts', function () {
            if ($scope.CustomerAccounts.length > 0 && $scope.loadingAccount) {
                if ($scope.filters.EntityId < 0) {
                    $scope.current.Account = $scope.CustomerAccounts[0];
                } else {
                    var foundAccount = false;
                    angular.forEach($scope.CustomerAccounts, function (value, key) {
                        if (value.EntityId == $scope.filters.EntityId) {
                            $scope.current.Account = $scope.CustomerAccounts[key];
                            foundAccount = true;
                        }
                    });
                    if (!foundAccount) $scope.current.Account = $scope.CustomerAccounts[0];
                }
                $scope.loadingAccount = false
            }
        });

        // Fetches the list of ordered by accounts
        $scope.getOrderedBy = function () {
            $scope.loadingOrderedBy = true;
            $http.post('/SampleOrdering/OrderHistory/getOrderedBy', $scope.filters).then(function (result) {
                $scope.OrderedBy = result.data;
            });
        };

        // this sets the orderby to the correct item if preset        
        $scope.$watch('OrderedBy', function () {
            if ($scope.OrderedBy.length > 0 && $scope.loadingOrderedBy) {
                angular.forEach($scope.OrderedBy, function (value, key) {
                    if (value.username == $scope.filters.UserName) {
                        $scope.current.OrderedBy = $scope.OrderedBy[key];
                    }
                });
                $scope.loadingOrderedBy = false;
            }
        });

        // Update if the current account is updated. 
        $scope.$watch('current.Account', function () {
            if (!$scope.loadingAccount) {
                // If the current account is not null set it to the EntityID for the account
                $scope.filters.EntityId = ($scope.current.Account == null) ?
                    null :
                    $scope.filters.EntityId = $scope.current.Account.EntityId;
                $scope.filters.Page = 1;
                $scope.current.Page = 1;
            }
        });

        // update the filter if the current orderedby updated
        $scope.$watch('current.OrderedBy', function () {
            if (!$scope.loadingOrderedBy) {
                $scope.filters.UserName = ($scope.current.OrderedBy == null) ?
                    null :
                    $scope.filters.UserName = $scope.current.OrderedBy.username;
                $scope.filters.Page = 1;
                $scope.current.Page = 1;
            }
        });

        // this updates the filters for sorting
        $scope.updateSort = function (fieldName) {

            // Use a COPY of the filters we do NOT want it triggering 
            // a bunch of watches. 
            var filters = angular.copy($scope.filters);

            // if the column is the same, the toggle the sort.
            if (filters.SortCol == fieldName) {
                filters.SortDir = (filters.SortDir == "A") ? "D" : "A";
            } else {
                // else just sort ascending 
                filters.SortCol = fieldName;
                filters.SortDir = "A";
            }
            filters.Page = 1;
            $scope.current.Page = 1;
            // Update the filters (triggers the watch)
            $scope.filters = filters;
        };

        // This fetches the history based on the filters
        $scope.getHistory = function () {
            $scope.isLoading = true;
            $http.post('/SampleOrdering/OrderHistory/getHistory', $scope.filters,
            ).then(function (result) {
                $scope.OrderHistoryItems = result.data.data;
                $scope.current.ItemCount = result.data.totalRows;
                $scope.ItemsPerPage = result.data.ItemsPerPage;
                $scope.isLoading = false;
            });
        };
        $scope.$watch('[isLoading, OrderHistoryItems]', function () {
            if ($scope.isLoading == false && $scope.OrderHistoryItems.length > 0) {
                var sortHeader = $('#resultstable th#hdr_' + $scope.filters.SortCol)
                sortHeader.addClass('sort');
                if ($scope.filters.SortDir == "A") {
                    sortHeader.addClass('sortasc');
                } else {
                    sortHeader.addClass('sortdesc');
                }
                //console.log($scope.current.ItemCount);
            }
        }, true);

        // Load the filters, accounts, and ordered by
        $scope.getFilters();

        $scope.$watch('current.Page', function () {
            if (!$scope.isLoading) {
                $scope.filters.Page = $scope.current.Page;
            }
        }, false);

    }]);

