﻿angular.module("orderSamples").controller("orderSamplesCheckoutController",
    ["$scope", "$http", "$uibModal", "$filter", "$window", function ($scope, $http, $uibModal, $filter, $window) {
        $scope.current = {
            Address: null,
            Account: '',
            ShippingMethod: '',
            EditAddress: null
        };

        $scope.filters = {
            ShippingInfo_SelectAccount: '',
            PageSize: 30,
            PageNumber: 1,
            SortField: 'Name',
            SortOrder: 0,
            Format: 'json'
        };
        

        $scope.selectedAddress = null;
        $scope.currentShippingMethod = '';

        $scope.shippingMethods = [];
        $scope.customerAccounts = [];
        $scope.AddressList = [];
        

        $scope.InitialLoad = true;
        $scope.pullAddress = false;

        $scope.modalVisible = false;
        $scope.modalDirty = false;

        $scope.LoadingAccounts = false;
        $scope.LoadingShippingMethods = true;
        

        //Search for accounts
        $scope.doSearch = function () {
            // Clear any suggestions lists
            $scope.accountSuggestions = [];
            $scope.LoadingAccounts = false;

            $http.get('/SampleOrdering/getCustomerShippingAccounts', {
                cache: true
            }).then(function (response) {
                $scope.customerAccounts = response.data;
                console.log('Refreshing Accounts Complete');
            });
        };

        //Test alert
        $scope.checkEntity = function () {
            $scope.dButton = true;

            try {
                $scope.current.Account.EntityId = $scope.current.Account.EntityId;
                //entity id was sucessfully found, enable continue button and hide error text
                $scope.dButton = false;
            }
            catch (err) {
                //entity id could not be found, disable continue button and show error text
                $scope.dButton = true;
            }
            
        };


        $scope.getAccountSuggestions = function (kw) {
            var target = angular.element('#suggestionList');
            target.css('display', 'block');

            if (kw.length >= 0) {
                // Filter the results here based on the value in the filter on screen
                $scope.accountSuggestions = [];

                angular.forEach($scope.customerAccounts, function (value, key) {
                    if (value.Name.toLowerCase().indexOf(kw.toLowerCase()) >= 0) {
                        $scope.accountSuggestions.push(value);
                    }
                });
            }
            else {
                angular.forEach($scope.customerAccounts, function (value, key) {
                        $scope.accountSuggestions.push(value);
                });
            }
        };

        $scope.clearAccountSuggestions = function () { 
            
            
            //if (event.target.id != "ShippingInfo_SelectAccount") {
            //    $scope.accountSuggestions = [];
            //}
            //var target = angular.element('#listOfAccounts');
            //target.css('display','none');
        }

        

        // Shipping Methods Start
        $scope.RefreshShippingMethods = function () {
            //console.log('Loading Shipping Methods');
            $http.get('/SampleOrdering/Checkout/getShippingMethods', {
                cache: true
            }).then(function (response) {
                $scope.shippingMethods = response.data;
                $scope.LoadingShippingMethods = false;
                //console.log('Loading Shipping End');
            });
        }
        // watch for when the shipping methods are loaded and then set the value 
        $scope.$watch('shippingMethods', function (newValue, oldValue) {
            //console.log('Finished loading shipping methods');
            if (typeof sessionStorage.currentShippingMethod == 'undefined') {
                $scope.current.ShippingMethod = null;
            } else {
                $scope.current.ShippingMethod = JSON.parse(sessionStorage.currentShippingMethod);
            }
        });
        // monitor the current shipping method.  
        $scope.$watch('current.ShippingMethod', function () {
            var isValid = false;
            if (!($scope.current.ShippingMethod == null)) {
                if ($scope.current.ShippingMethod.hasOwnProperty('Id')) {
                    isValid = $scope.current.ShippingMethod.Id > "0";
                }
            }
            if (isValid) {
                sessionStorage.currentShippingMethod = JSON.stringify($scope.current.ShippingMethod);
            } else {
                $scope.clearStorageItem('currentShippingMethod');
            }
        });
        // Shipping Methods End

        // Account Changes Start
        $scope.RefreshAccounts = function () {
            $scope.LoadingAccounts = true;
            //console.log('Refreshing Accounts');
            $http.get('/SampleOrdering/getCustomerShippingAccounts', {
                cache: true
            }).then(function (response) {
                $scope.customerAccounts = response.data;                
                $scope.LoadingAccounts = false;
                //console.log('Refreshing Accounts Complete');
            });
        }

        $scope.setCurrentAccount = function (account) {
            
                $scope.accountSuggestions = [];
                $scope.current.Account = account;
                $scope.filters.ShippingInfo_SelectAccount = account.Name;
            
            
        }
        
        $scope.$watch('customerAccounts', function (newValue, oldValue) {
            //console.log('Finished loading Accounts');
            if ($scope.customerAccounts.length > 0) {
                if ($scope.customerAccounts.length > 1) {
                    if (typeof sessionStorage.currentAccount === 'undefined') {
                        $scope.current.Account = null
                    } else {
                        $scope.current.Account = JSON.parse(sessionStorage.currentAccount);
                        $scope.filters.ShippingInfo_SelectAccount = $scope.current.Account.Name;
                    }
                } else if ($scope.customerAccounts.length == 1) {
                    $scope.current.Account = $scope.customerAccounts[0];
                    $scope.filters.ShippingInfo_SelectAccount = $scope.customerAccounts[0].Name;
                } else {
                    $scope.current.Account = null;
                }
            }
        });


        $scope.$watch('current.Account', function () {
            var isValid = false;
            $scope.AddressList = [];
            if ($scope.customerAccounts.length > 0) {
                if (!($scope.current.Account == null)) {
                    if ($scope.current.Account.hasOwnProperty('Id')) {
                        isValid = $scope.current.Account.Id === 0 || $scope.current.Account.Id > 0;
                    }
                }
                if (isValid) {
                    sessionStorage.currentAccount = JSON.stringify($scope.current.Account);
                    $scope.RefreshAddresses();
                } else {
                    $scope.clearStorageItem('currentAccount');
                }
            }
        });
        // Account Changes End
        $scope.RefreshAddresses = function () {
            $http.get('/SampleOrdering/getAddresses/' + $scope.current.Account.EntityId, {
                cache: false
            }).then(function (response) {
                $scope.AddressList = response.data;
            });
        }
        // Address Radio Selection Start        
        $scope.$watch('AddressList', function () {
            if ($scope.AddressList.length === 1) {
                $scope.current.Address = $scope.AddressList[0];
            } else if ($scope.AddressList.length > 1) {
                if (!(typeof sessionStorage.currentAddress == 'undefined')) {
                    $scope.current.Address = JSON.parse(sessionStorage.currentAddress);
                } else {
                    $scope.current.Address = $scope.AddressList[0];
                }
            } else {
                $scope.current.Address = null;
            }
        });
      
        $scope.$watch('current.Address', function () {
            //console.log('$scope.$watch(\'currentAddress\'')
            if ($scope.current.Address != null) {
                //console.log('Current Address Updated');
                $scope.saveSelectedAddressInternal($scope.current.Address);             
                sessionStorage.currentAddress = JSON.stringify($scope.current.Address);
            } else {
                $scope.clearStorageItem('currentAddress');
            }
        }, true);

        $scope.submitForm = function (e) {
            e.preventDefault();            
            $scope.saveSelectedAddress();
            if ($scope.shippingInfoForm.$valid) {
                document.getElementById("shippingInfoFormId").submit();
            }


        }

        $scope.backToCart = function (e) {
            //e.preventDefault();
            $scope.saveSelectedAddress();

        }



        $scope.saveSelectedAddress = function () {
            //console.log('Saving Addrss');
            $scope.selectedAddress.EntityId = $scope.current.Account.EntityId;
            $scope.selectedAddress.EntityName = $scope.current.Account.EntityName;
            $scope.selectedAddress.Repid = $scope.current.Account.Repid;
            sessionStorage.selectedAddress = JSON.stringify($scope.selectedAddress);
        }

        $scope.setSelectedAddress = function (selAddress) {
            $scope.selectedAddress = selAddress;
        }

        $scope.checkString = function (value) {
            if (typeof value == 'undefined') {
                return false;
            } else if (value == null) {
                return false;
            } else if (value.length > 0) {
                return true;
            }
        }

        $scope.getAddressById = function (Id) {
            var tmpAddress = false;
            angular.forEach($scope.AddressList, function (value, key) {
                if (value.Id === Id) {
                    tmpAddress = angular.copy(value);
                }
            });
            return tmpAddress;
        }

        $scope.editAddress = function (Id) {
            $scope.saveBeforeEdit();
            if (Id > 0) {
                $scope.current.EditAddress = $scope.getAddressById(Id);
            } else {
                $scope.current.EditAddress = {
                    Id: 0,
                    Name: '',
                    Address1: '',
                    Address2: '',
                    City: '',
                    State: '',
                    Zip: '',
                    Country: '',
                    Phone: '',
                    SpecialInstructions: '',
                    Repid: '',
                    EntityId: $scope.current.Account.EntityId,
                    EntityName: $scope.current.Account.EntityName
                };
            }
            $("#modalAddressEdit").modal();
        }

        $scope.saveAddress = function () {
            $scope.saveBeforeEdit();
            $http.post('/SampleOrdering/Checkout/UpdateCustomerAddress', {
                AddressToUpdate: $scope.current.EditAddress,
                cache: false
            }).then(function (response) {
                if (response.data.status == 'success') {  
                    $scope.RefreshAddresses();
                    $scope.saveSelectedAddressInternal($scope.current.Address);
                } else {
                    $scope.message = response.data.message;
                }
            });
            $("#modalAddressEdit").modal('hide');
        }

        $scope.saveBeforeEdit = function () {
            if ($scope.oldAddress == null) {
                $scope.oldAddress = angular.copy($scope.selectedAddress);
            }
        }

        $scope.recoverAfterEdit = function () {
            if ($scope.oldAddress != null) {
                $scope.selectedAddress.ContactEmail = $scope.oldAddress.ContactEmail;
                $scope.selectedAddress.CustomerName = $scope.oldAddress.CustomerName;
            }
            $scope.oldAddress = null;
        }

        $scope.saveSelectedAddressInternal = function (newAddress) {
            if (newAddress != null) {
                $scope.saveBeforeEdit();
                $scope.selectedAddress = angular.copy(newAddress);
                $scope.recoverAfterEdit();
            }
        }

        $scope.deleteAddress = function () {
            var r = confirm("Are you sure you want to delete this address?");
            if (r == true) {
                if ($scope.current.Address.Source == 'J') {
                    $scope.message = 'Cannot Delete this address';
                } else {
                    $http.post('/SampleOrdering/Checkout/DeleteCustomerAddress', {
                        AddressToUpdate: $scope.current.EditAddress,
                        cache: false
                    }).then(function (response) {
                        if (response.data.status == 'success') {
                            $scope.currentAddress = $scope.current.EditAddress;
                        } else {
                            $scope.message = response.data.message;
                        }
                        $scope.RefreshAddresses();
                        $("#modalAddressEdit").modal('hide');
                    });

                }
            } else {

            }
        }

        $("#modalAddressEdit").on("shown.bs.modal", function () {
            $scope.oldShippingInfo = angular.copy($scope.currentAddress);
            $scope.modalDirty = false;
            $scope.modalVisible = true
        });

        $("#modalAddressEdit").on("hide.bs.modal", function () {
            $scope.AddressList
            if ($scope.selectedAddressId > 0) {
                $scope.selectedAddress = $scope.getAddressById($scope.selectedAddressId);
            }
            $scope.modalVisible = false
        });

        $scope.sessionSaveShipping = function () {
            //console.log('Save Shipping');
            sessionStorage.currentAccount = JSON.stringify($scope.current.Account);
            sessionStorage.currentAddress = JSON.stringify($scope.current.Address);
            sessionStorage.selectedAddress = JSON.stringify($scope.selectedAddress);
        }

        $scope.sessionClearShipping = function () {
            //console.log('Clear Shipping');
            $scope.clearStorageItem('currentAccount');
            $scope.clearStorageItem('currentAddress');
            $scope.clearStorageItem('selectedAddress');
            $scope.clearStorageItem('currentShippingMethod');
            return "";
        }

        $scope.clearStorageItem = function (item) {
            if (typeof sessionStorage.getItem(item) != 'undefined')
                sessionStorage.removeItem(item);
        }
        $scope.LoadSelectedAddress = function () {
            if (typeof sessionStorage.selectedAddress != 'undefined') {
                $scope.selectedAddress = JSON.parse(sessionStorage.selectedAddress);
            }
        }
        $scope.LoadSelectedAddress()
        $scope.RefreshShippingMethods();
        $scope.RefreshAccounts();

    }]);
