﻿angular.module("orders").controller("ordersController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {

    $scope.orders = [];
    $scope.statusOptionsDisplay = [];
    $scope.totalPages = 1;
    $scope.resultsCount = 0;
    $scope.allAccounts = [];
    $scope.allAccountsNames = [];
    $scope.serializedFilters = '';
    

    $scope.filters = {
        Company: $('#custCompany').val(),
        CustomerNumber: $('#account-dropdown').val(),
        CustomerName: $('#name-dropdown').val().trim(),
        AA: $scope.allAccounts,
        AAN: $scope.allAccountsNames,
        OrderStatus: '',
        JobName: '',
        Plant: '',
        PurchaseOrder: '',
        OrderNumber: '',
        OrderQuantity: null,
        DeliveredQuantity: null,
        CancelledQuantity: null,
        DateRequested: '',
        OrderDate: '',
        SortOrder: 'D',
        SortField: 'OrderDate',
        StartDate: null,
        EndDate: null
        
    };

    $scope.numAccounts = $('#numAccounts').val();

    $scope.returnType = function (getType) {
        return typeof getType;

    };

    $scope.setSearchFilters = function () {
        $scope.filters.PurchaseOrder = $scope.filters.OrderNumber;
        $scope.filters.JobName = $scope.filters.OrderNumber;

        $scope.serializedFilters = JSON.stringify($scope.filters);
    };

    $scope.clearSearch = function () {
        $scope.statusOptionsDisplay = [];

        $scope.filters = {
            Company: $('#custCompany').val(),
            CustomerNumber: $('#account-dropdown').val(),
            CustomerName: $('#name-dropdown').val().trim(),
            AA: $scope.allAccounts,
            AAN: $scope.allAccountsNames,
            OrderStatus: '',
            JobName: '',
            Plant: '',
            PurchaseOrder: '',
            OrderNumber: '',
            OrderQuantity: null,
            DeliveredQuantity: null,
            CancelledQuantity: null,
            DateRequested: '',
            OrderDate: '',
            SortOrder: 'D',
            SortField: 'OrderDate',
            StartDate: null,
            EndDate: null
        };
         
        $scope.doSearch();
    };

    $scope.setName = function () {
        $scope.filters.CustomerName = $('#name-dropdown').val().trim();
    };

    $scope.encodeAll = function (input) {

        return btoa(input);
    };
    
    $scope.doSearch = function (preservePage) {
        
        $scope.setSearchFilters();

        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;

        //get rid of sorting
        $('#resultstableB th').removeClass('sort');
        $('#resultstableB th').removeClass('sortasc');
        $('#resultstableB th').removeClass('sortdesc');

        //get data
        $http.get('/Orders/Admin/API/GetOrders', {
            params: $scope.filters
        }).then(function (result) {
            $scope.loading = false;
            
            //get data for dropdowns
            $scope.plantOptions = result.data.Plants;
            $scope.statusOptions = result.data.Statuses;

            $scope.statusOptionsDisplay = [];
            
            for (var i = 0; i < $scope.statusOptions.length; i++) {
                
                if ($scope.statusOptionsDisplay.indexOf("Closed") === -1) {
                    if ($scope.statusOptions[i] < 1) {
                        $scope.statusOptionsDisplay.push("Closed");
                    }
                }

                if ($scope.statusOptionsDisplay.indexOf("Open") === -1) {
                    if ($scope.statusOptions[i] > 0) {
                        $scope.statusOptionsDisplay.push("Open");
                    }
                }
                
            }            
            
            $scope.orders = result.data.OrderList;
            $scope.totalPages = result.data.TotalPages;
            $scope.resultsCount = result.data.ResultsCount;
            

            $scope.PO = result.data.PO;

            var sortHeader = $('#resultstableB th#hdr_' + $scope.filters.SortField);
            sortHeader.addClass('sort');
            if ($scope.filters.SortOrder === 'A') {
                sortHeader.addClass('sortasc');
            } else {
                sortHeader.addClass('sortdesc');
            }          

        });

        $cookies.put('ocOrderStatus', $scope.filters.BalanceQuantity);
        $cookies.put('ocPlant', $scope.filters.Plant);
        $cookies.put('ocOrderNum', $scope.filters.OrderNumber);


        //if there are multiple accounts, set cookie for customer number and customer name
        if ($scope.numAccounts > 1) {
            $cookies.put('ocCustomerNumber', $scope.filters.CustomerNumber);
            $cookies.put('ocCustomerName', $scope.filters.CustomerName);
        }

        if ($scope.filters.StartDate === null) {
            $cookies.put('ocStartDate', "");
        }
        else {
            $cookies.put('ocStartDate', $scope.filters.StartDate);
        }
        if ($scope.filters.EndDate === null) {
            $cookies.put('ocEndDate', "");
        }
        else {
            $cookies.put('ocEndDate', $scope.filters.EndDate);
        }
    };
        
    $scope.changeSortField = function (newSortField) {
        if (newSortField !== null) {
            if ($scope.filters.SortField === newSortField) {
                if ($scope.filters.SortOrder === 'A') {
                    $scope.filters.SortOrder = 'D';
                }
                else if ($scope.filters.SortOrder === 'D') {
                    $scope.filters.SortOrder = 'A';
                }
                else {
                    $scope.filters.SortOrder = 'A';
                }
            }
            else {
                $scope.filters.SortOrder = 'A';
            }
            $scope.filters.SortField = newSortField;

        }
        $scope.doSearch();

    };

    $scope.changePage = function (selectedPage) {
        $scope.filters.PageNumber = selectedPage;
        $scope.doSearch(true);
        return false;
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i === 1 || i === numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }
        }

        return pages;
    };


    $scope.sort = function (fieldName) {
        if ($scope.filters.SortField === fieldName) {
            if ($scope.filters.SortOrder === 'A')
                $scope.filters.SortOrder = 'D';
            else
                $scope.filters.SortOrder = 'A';
        }
        else {
            $scope.filters.SortField = fieldName;
            $scope.filters.SortOrder = 'A';
        }
        $('#resultstable th').removeClass('sort');
        $('#resultstable th').removeClass('sortasc');
        $('#resultstable th').removeClass('sortdesc');
        var sortHeader = $('#resultstable th#hdr_' + $scope.filters.SortField);
        sortHeader.addClass('sort');
        if ($scope.filters.SortOrder === 'A') {
            sortHeader.addClass('sortasc');
        } else {
            sortHeader.addClass('sortdesc');
        }
        $scope.doSearch();
    };

    //Cookie code
    $scope.setInitialFilter = function () {
        var userType = $('#userType').val();
        if (userType === '06') {
            // Don't include last select option because it will be the "select all" option 
            for (var i = 0; i < $('#account-dropdown > option').length - 1; i++) {
                $scope.allAccounts.push($('#account-dropdown > option').eq(i).val());
            }
            for (var j = 0; j < $('#name-dropdown > option').length - 1; j++) {
                $scope.allAccountsNames.push($('#name-dropdown > option').eq(j).val());
            }
        }
        
        if (angular.isDefined($cookies.get('ocOrderNum'))) {
            $scope.filters.OrderNumber = $cookies.get('ocOrderNum');
        }

        //if there are multiple accounts, set cookie for customer number and customer name
        if ($scope.numAccounts > 1) {
            if (angular.isDefined($cookies.get('ocCustomerNumber'))) {
                $scope.filters.CustomerNumber = $cookies.get('ocCustomerNumber');
            }
            if (angular.isDefined($cookies.get('ocCustomerName'))) {
                $scope.filters.CustomerName = $cookies.get('ocCustomerName');
            }
        }
        
        if (angular.isDefined($cookies.get('ocOrderStatus'))) {
            $scope.filters.BalanceQuantity = $cookies.get('ocOrderStatus');
        }
        if (angular.isDefined($cookies.get('ocPlant'))) {
            if ($cookies.get('ocPlant') !== "null") {
                $scope.filters.Plant = $cookies.get('ocPlant');
            }
            else {
                $scope.filters.Plant = "";
            }
        }
        if (angular.isDefined($cookies.get('ocStartDate'))) {
            $scope.filters.StartDate = $cookies.get('ocStartDate');
        }
        if (angular.isDefined($cookies.get('ocEndDate'))) {
            $scope.filters.EndDate = $cookies.get('ocEndDate');
        }
    };
    
    $scope.setInitialFilter();
    $scope.doSearch();
}]);


