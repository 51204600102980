﻿angular.module("orderSamples").controller("orderSamplesFulfillmentsController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {

    $scope.orders = [];

    $scope.productNameSuggestions = [];
    $scope.orderNumberSuggestions = [];
    $scope.companyNameSuggestions = [];
    

    $scope.totalPages = 1;

    $scope.filters = {
        OrderStatus: '',
        CompanyName: '',
        ProductName: '',
        OrderNumber: '',
        Plant: '',
        SampleTypeId: null,
        StartDate: null,
        EndDate: null,
        ContainsCustomSample: false,
        PageSize: 30,
        PageNumber: 1,
        SortField: 'OrderDate',
        IncludeShipped: false,
        SortOrder: 'A',
        PlantFilter: $('#plantFilter').val()
    };



    $scope.doSearch = function (preservePage) {
        // Clear any suggestions lists
        $scope.productNameSuggestions = [];
        $scope.orderNumberSuggestions = [];
        $scope.companyNameSuggestions = [];
        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;
        $('#resultstableB th').removeClass('sort');
        $('#resultstableB th').removeClass('sortasc');
        $('#resultstableB th').removeClass('sortdesc');
        $http.get('/SampleOrdering/Admin/API/GetFulfillments', {
            params: $scope.filters
        }).then(function (result) {
            $scope.loading = false;
            $scope.plantOptions = result.data.Plants;
            $scope.statusOptions = result.data.Statuses;
            $scope.sampleTypeOptions = result.data.SampleTypes;
            $scope.orders = result.data.OrderList;

            //remove time from date string
            for (var i = 0; i < $scope.orders.length; i++) {
                var spaceIndex = $scope.orders[i].Date.indexOf(" ");
                $scope.orders[i].Date = $scope.orders[i].Date.substring(0, spaceIndex);
                
                //var dateonly = $scope.orders[i].date;
                //dateonly = dateonly.split(' ')[0];
                //$scope.orders[i].date = dateonly;
            }
            

            $scope.totalPages = result.data.TotalPages;
            var sortHeader = $('#resultstableB th#hdr_' + $scope.filters.SortField);
            sortHeader.addClass('sort');
            if ($scope.filters.SortOrder == 'A') {
                sortHeader.addClass('sortasc');
            } else {
                sortHeader.addClass('sortdesc');
            }
            
            });

        if ($scope.filters.OrderStatus == "Open" || $scope.filters.OrderStatus == "Fulfillment") {
            $scope.filters.IncludeShipped = false;
        }

        if ($scope.filters.OrderStatus == null) {

        }
        else {
            $cookies.put('osfcOrderStatus', $scope.filters.OrderStatus);
        }
        $cookies.put('osfcCompanyName', $scope.filters.CompanyName);
        $cookies.put('osfcProductName', $scope.filters.ProductName);
        $cookies.put('osfcOrderNumber', $scope.filters.OrderNumber);
        $cookies.put('osfcPlant', $scope.filters.Plant);
        $cookies.put('osfcSampleTypeId', $scope.filters.SampleTypeId);

        if ($scope.filters.StartDate == null) {
            //$cookies.put('osfcStartDate', null);
        }
        else {
            $cookies.put('osfcStartDate', $scope.filters.StartDate);
        }
        if ($scope.filters.EndDate == null) {
            //$cookies.put('osfcEndDate', null);
        }
        else {
            $cookies.put('osfcEndDate', $scope.filters.EndDate);
        }
        
        $cookies.put('osfcContainsCustomSample', $scope.filters.ContainsCustomSample);
        $cookies.put('osfcIncludeShipped', $scope.filters.IncludeShipped);


    };

    $scope.getProductNameSuggestions = function (kw) {
        if (kw.length > 1) {
            $http.get('/SampleOrdering/Admin/API/GetProductNameSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.productNameSuggestions = response.data;
            });
        }
    };

    $scope.getOrderNumberSuggestions = function (kw) {
        if (kw.length > 1) {
            $http.get('/SampleOrdering/Admin/API/GetOrderNumberSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.orderNumberSuggestions = response.data;
            });
        }
    };

    $scope.getCompanyNameSuggestions = function (kw) {
        if (kw.length > 1) {
            $http.get('/SampleOrdering/Admin/API/GetCompanyNameSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.companyNameSuggestions = response.data;
            });
        }
    };

    $scope.changePage = function (selectedPage) {
        $scope.filters.PageNumber = selectedPage;
        $scope.doSearch(true);
        return false;
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i == 1 || i == numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }

        }

        return pages;
    }

    $scope.clearSearch = function () {
        $scope.filters = {
            //SortField: 'OrderDate',
            //SortOrder: $('#exportSortOrder').val(),
            //PageSize: 30,
            //PageNumber: 1,
            //PlantFilter: $('#plantFilter').val()



            OrderStatus: '',
            CompanyName: '',
            ProductName: '',
            OrderNumber: '',
            Plant: '',
            SampleTypeId: null,
            StartDate: null,
            EndDate: null,
            ContainsCustomSample: false,
            PageSize: 30,
            PageNumber: 1,
            SortField: 'OrderDate',
            IncludeShipped: false,
            SortOrder: 'A',
            PlantFilter: $('#plantFilter').val()
        };
       
        $scope.doSearch();
    };

    $scope.sort = function (fieldName) {
        if ($scope.filters.SortField == fieldName) {
            if ($scope.filters.SortOrder == 'A')
                $scope.filters.SortOrder = 'D'
            else
                $scope.filters.SortOrder = 'A';
        }
        else {
            $scope.filters.SortField = fieldName;
            $scope.filters.SortOrder = 'A';
        }
        $('#resultstable th').removeClass('sort');
        $('#resultstable th').removeClass('sortasc');
        $('#resultstable th').removeClass('sortdesc');
        var sortHeader = $('#resultstable th#hdr_' + $scope.filters.SortField)
        sortHeader.addClass('sort');
        if ($scope.filters.SortOrder == 'A') {
            sortHeader.addClass('sortasc');
        } else {
            sortHeader.addClass('sortdesc');
        }
        $scope.doSearch();
    };


    //Cookie code
    $scope.setInitialFilter = function () {

        if (angular.isDefined($cookies.get('osfcOrderStatus'))) {
            if ($cookies.get('osfcOrderStatus') == "Shipped" && $cookies.get('osfcIncludeShipped') == false) {

            }
            else {
                $scope.filters.OrderStatus = $cookies.get('osfcOrderStatus');
            }
        }

        if (angular.isDefined($cookies.get('osfcCompanyName'))) {
            $scope.filters.CompanyName = $cookies.get('osfcCompanyName');
        }
        if (angular.isDefined($cookies.get('osfcProductName'))) {
            $scope.filters.ProductName = $cookies.get('osfcProductName');
        }
        if (angular.isDefined($cookies.get('osfcOrderNumber'))) {
            $scope.filters.OrderNumber = $cookies.get('osfcOrderNumber');
        }
        if (angular.isDefined($cookies.get('osfcPlant'))) {
            $scope.filters.Plant = $cookies.get('osfcPlant');
        }
        if (angular.isDefined($cookies.get('osfcSampleTypeId'))) {
            $scope.parseSampleTypeId = parseInt($cookies.get('osfcSampleTypeId'));
            $scope.filters.SampleTypeId = $scope.parseSampleTypeId;
        }
        if (angular.isDefined($cookies.get('osfcStartDate'))) {
            $scope.filters.StartDate = $cookies.get('osfcStartDate');
        }
        if (angular.isDefined($cookies.get('osfcEndDate'))) {
            $scope.filters.EndDate = $cookies.get('osfcEndDate');
        }
        if (angular.isDefined($cookies.get('osfcContainsCustomSample'))) {
            $scope.containsCustomTF; 
            if ($cookies.get('osfcContainsCustomSample') == "true") {
                $scope.containsCustomTF = true;
            }
            else {
                $scope.containsCustomTF = false;
            }
            $scope.filters.ContainsCustomSample = $scope.containsCustomTF;
        }

        if (angular.isDefined($cookies.get('osfcIncludeShipped'))) {
            $scope.includeShipTF;
            if ($cookies.get('osfcIncludeShipped') == "true") {
                $scope.includeShipTF = true;
            }
            else {
                $scope.includeShipTF = false;
            }
            $scope.filters.IncludeShipped = $scope.includeShipTF;;
        }
        
    };

  

   

    $scope.setInitialFilter();
    $scope.doSearch();
}]);


