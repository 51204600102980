﻿angular.module("searchshiptickets").controller("searchShipTicketsController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {

    $scope.shipTickets = [];

    $scope.totalPages = 1;

    $scope.resultsCount = 0;


    $scope.filters = {
        Company: $('#custCompany').val(),
        CustomerNumber: $('#account-dropdown').val(),
        CustomerName: $('#name-dropdown').val().trim(),
        OrderNumber: '',
        TicketNumber: '',
        InvoiceNumber: '',
        Plant: '',
        StartDate: null,
        EndDate: null,
        SortOrder: 'D',
        SortField: 'ShipDate'

    };


    $scope.orderParams = {
        CustomerNumber: $('#account-dropdown').val(),
        Company: $('#custCompany').val()
    };

    $scope.numAccounts = $('#numAccounts').val();

    $scope.returnType = function (getType) {
        return typeof getType;

    };



    $scope.clearSearch = function () {

        $scope.filters = {
            Company: $('#custCompany').val(),
            CustomerNumber: $('#account-dropdown').val(),
            CustomerName: $('#name-dropdown').val().trim(),
            OrderNumber: '',
            TicketNumber: '',
            InvoiceNumber: '',
            Plant: '',
            StartDate: null,
            EndDate: null,
            SortOrder: 'D',
            SortField: 'ShipDate'
        };

        $scope.doSearch();
    };

    $scope.setName = function () {
        $scope.filters.CustomerName = $('#name-dropdown').val().trim();
    }

    $scope.setCustomerNumber = function () {
        $scope.orderParams.CustomerNumber = $('#account-dropdown').val().trim();
    }



    $scope.encodeAll = function (input) {

        return btoa(input);
    }

    $scope.doSearch = function (preservePage) {


        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;

        //get rid of sorting
        $('#resultstableB th').removeClass('sort');
        $('#resultstableB th').removeClass('sortasc');
        $('#resultstableB th').removeClass('sortdesc');

        //get data
        $http.get('/SearchShipTickets/GetTickets', {
            params: $scope.filters
        }).then(function (response) {
            $scope.loading = false;

            //get data for dropdowns
            $scope.plantOptions = response.data.Plants;

            $scope.shipTickets = response.data.ShipTicketList;
            $scope.totalPages = response.data.TotalPages;
            $scope.resultsCount = response.data.ResultsCount;



            var sortHeader = $('#resultstableB th#hdr_' + $scope.filters.SortField);
            sortHeader.addClass('sort');
            if ($scope.filters.SortOrder == 'A') {
                sortHeader.addClass('sortasc');
            } else {
                sortHeader.addClass('sortdesc');
            }


        });


        //$cookies.put('ocOrderStatus', $scope.filters.BalanceQuantity);
        //$cookies.put('ocPlant', $scope.filters.Plant);
        //$cookies.put('ocOrderNum', $scope.filters.OrderNumber);


        ////if there are multiple accounts, set cookie for customer number and customer name
        //if ($scope.numAccounts > 1) {
        //    $cookies.put('ocCustomerNumber', $scope.filters.CustomerNumber);
        //    $cookies.put('ocCustomerName', $scope.filters.CustomerName);
        //}



        //if ($scope.filters.StartDate == null) {
        //    $cookies.put('ocStartDate', "");
        //}
        //else {
        //    $cookies.put('ocStartDate', $scope.filters.StartDate);
        //}
        //if ($scope.filters.EndDate == null) {
        //    $cookies.put('ocEndDate', "");
        //}
        //else {
        //    $cookies.put('ocEndDate', $scope.filters.EndDate);
        //}


    };

    $scope.changeSortField = function (newSortField) {
        if (newSortField != null) {
            if ($scope.filters.SortField == newSortField) {
                if ($scope.filters.SortOrder == 'A') {
                    $scope.filters.SortOrder = 'D';
                }
                else if ($scope.filters.SortOrder == 'D') {
                    $scope.filters.SortOrder = 'A';
                }
                else {
                    $scope.filters.SortOrder = 'A';
                }
            }
            else {
                $scope.filters.SortOrder = 'A';
            }
            $scope.filters.SortField = newSortField;

        }
        $scope.doSearch();

    }

    $scope.changePage = function (selectedPage) {
        $scope.filters.PageNumber = selectedPage;
        $scope.doSearch(true);
        return false;
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i == 1 || i == numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }

        }

        return pages;
    }


    $scope.sort = function (fieldName) {
        if ($scope.filters.SortField == fieldName) {
            if ($scope.filters.SortOrder == 'A')
                $scope.filters.SortOrder = 'D'
            else
                $scope.filters.SortOrder = 'A';
        }
        else {
            $scope.filters.SortField = fieldName;
            $scope.filters.SortOrder = 'A';
        }
        $('#resultstable th').removeClass('sort');
        $('#resultstable th').removeClass('sortasc');
        $('#resultstable th').removeClass('sortdesc');
        var sortHeader = $('#resultstable th#hdr_' + $scope.filters.SortField)
        sortHeader.addClass('sort');
        if ($scope.filters.SortOrder == 'A') {
            sortHeader.addClass('sortasc');
        } else {
            sortHeader.addClass('sortdesc');
        }
        $scope.doSearch();
    };


    ////Cookie code
    //$scope.setInitialFilter = function () {
    //    if (angular.isDefined($cookies.get('ocOrderNum'))) {
    //        $scope.filters.OrderNumber = $cookies.get('ocOrderNum');
    //    }

    //    //if there are multiple accounts, set cookie for customer number and customer name
    //    if ($scope.numAccounts > 1) {
    //        if (angular.isDefined($cookies.get('ocCustomerNumber'))) {
    //            $scope.filters.CustomerNumber = $cookies.get('ocCustomerNumber');
    //        }
    //        if (angular.isDefined($cookies.get('ocCustomerName'))) {
    //            $scope.filters.CustomerName = $cookies.get('ocCustomerName');
    //        }
    //    }

    //    if (angular.isDefined($cookies.get('ocOrderStatus'))) {
    //        $scope.filters.BalanceQuantity = $cookies.get('ocOrderStatus');
    //    }
    //    if (angular.isDefined($cookies.get('ocPlant'))) {
    //        if ($cookies.get('ocPlant') != "null") {
    //            $scope.filters.Plant = $cookies.get('ocPlant');
    //        }
    //        else {
    //            $scope.filters.Plant = "";
    //        }
    //    }
    //    if (angular.isDefined($cookies.get('ocStartDate'))) {
    //        $scope.filters.StartDate = $cookies.get('ocStartDate');
    //    }
    //    if (angular.isDefined($cookies.get('ocEndDate'))) {
    //        $scope.filters.EndDate = $cookies.get('ocEndDate');
    //    }


    //};

    //set cookie
    //$scope.setInitialFilter();




    $scope.getOrders = function (preservePage) {

        //get data
        $http.get('/Orders/Admin/API/AddOrderSessions', {
            params: $scope.orderParams
        }).then(function (response) {

        });

    };

    $scope.getOrders();
    $scope.doSearch();
}]);


