﻿angular.module("orderInventory").controller("orderInventoryFulfillmentsController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {

    $scope.orders = [];

    $scope.productNameSuggestions = [];
    $scope.orderNumberSuggestions = [];
    $scope.customerNameSuggestions = [];

    $scope.totalPages = 1;

    $scope.filters = {
        OrderStatus: '',
        CustomerName: '',
        ProductName: '',
        OrderNumber: '',
        Plant: '',
        SampleTypeId: null,
        StartDate: null,
        EndDate: null,
        ContainsCustomSample: false,
        PageSize: 30,
        PageNumber: 1,
        SortField: 'OrderDate',
        SortOrder: 'A',
        PlantFilter: $('#plantFilter').val(),        
    };

 
    $scope.doSearch = function (preservePage) {
       
        // Clear any suggestions lists
        $scope.productNameSuggestions = [];
        $scope.orderNumberSuggestions = [];
        $scope.customerNameSuggestions = [];
        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;
        $('#resultstableB th').removeClass('sort');
        $('#resultstableB th').removeClass('sortasc');
        $('#resultstableB th').removeClass('sortdesc');
        $http.get('/InventoryOrdering/Admin/API/GetFulfillments', {
            params: $scope.filters
        }).then(function (result) {
            $scope.loading = false;
            $scope.plantOptions = result.data.Plants;
            $scope.statusOptions = result.data.Statuses;
            $scope.sampleTypeOptions = result.data.SampleTypes;
            $scope.orders = result.data.OrderList;
            $scope.totalPages = result.data.TotalPages;
            
            var sortHeader = $('#resultstableB th#hdr_' + $scope.filters.SortField);
          sortHeader.addClass('sort');
            if ($scope.filters.SortOrder == 'A') {
                sortHeader.addClass('sortasc');
            } else {
                sortHeader.addClass('sortdesc');
          }


            //cookie code
            //if (result.data.OrderList.length == 0) {
                
            //    $scope.filters = {
            //        OrderStatus: '',
            //        CustomerName: '',
            //        ProductName: '',
            //        OrderNumber: '',
            //        Plant: '',
            //        SampleTypeId: null,
            //        StartDate: null,
            //        EndDate: null,
            //        ContainsCustomSample: false,
            //        PageSize: 30,
            //        PageNumber: 1,
            //        SortField: 'OrderDate',
            //        SortOrder: 'A',
            //        PlantFilter: $('#plantFilter').val(),
            //    };
            //    $cookies.put('oifcCustomerName', $scope.filters.CustomerName);
            //    $cookies.put('oifcProductName', $scope.filters.ProductName);
            //    $cookies.put('oifcOrderNumber', $scope.filters.OrderNumber);
            //    $cookies.put('oifcStartDate', $scope.filters.StartDate);
            //    $cookies.put('oifcEndDate', $scope.filters.EndDate);
            //    $cookies.put('oifcPlant', $scope.filters.Plant);
                
            //    $scope.doSearch();
            //}

            });

        if ($scope.filters.StartDate == null) {
            $scope.filters.StartDate = "";
        }

        if ($scope.filters.EndDate == null) {
            $scope.filters.EndDate = "";
        }

        $cookies.put('oifcCustomerName', $scope.filters.CustomerName);
        $cookies.put('oifcProductName', $scope.filters.ProductName);
        $cookies.put('oifcOrderNumber', $scope.filters.OrderNumber);
        $cookies.put('oifcStartDate', $scope.filters.StartDate);
        $cookies.put('oifcEndDate', $scope.filters.EndDate);
        $cookies.put('oifcPlant', $scope.filters.Plant);

        

    };

    $scope.getProductNameSuggestions = function (kw) {
        if (kw.length > 2) {
            $http.get('/InventoryOrdering/Admin/API/GetProductNameSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.productNameSuggestions = response.data;
            });
        }
    };

    $scope.getOrderNumberSuggestions = function (kw) {
        if (kw.length > 2) {
            $http.get('/InventoryOrdering/Admin/API/GetOrderNumberSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.orderNumberSuggestions = response.data;
            });
        }
    };

    $scope.getCustomerSuggestions = function (kw) {
        if (kw.length > 2) {
            $http.get('/InventoryOrdering/Admin/API/GetCustomerSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.customerNameSuggestions = response.data;
            });
        }
    };

    $scope.changePage = function (selectedPage) {
        $scope.filters.PageNumber = selectedPage;
        $scope.doSearch(true);
        return false;
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i == 1 || i == numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }

        }

        return pages;
    }

    $scope.clearSearch = function () {
        $scope.filters = {
            SortField: 'OrderDate',
            SortOrder: $('#exportSortOrder').val(),
            PageSize: 30,
            PageNumber: 1,
            PlantFilter: $('#plantFilter').val()
        };

        $scope.doSearch();
    };

    $scope.sort = function (fieldName) {
        if ($scope.filters.SortField == fieldName) {
            if ($scope.filters.SortOrder == 'A')
                $scope.filters.SortOrder = 'D'
            else
                $scope.filters.SortOrder = 'A';
        }
        else {
            $scope.filters.SortField = fieldName;
            $scope.filters.SortOrder = 'A';
        }

        $scope.doSearch();
    };




    //cookie code
    $scope.setInitialFilter = function () {

        if (angular.isDefined($cookies.get('oifcCustomerName'))) {
            $scope.filters.CustomerName = $cookies.get('oifcCustomerName');
        }
        if (angular.isDefined($cookies.get('oifcProductName'))) {
            $scope.filters.ProductName = $cookies.get('oifcProductName');
        }
        if (angular.isDefined($cookies.get('oifcOrderNumber'))) {
            $scope.filters.OrderNumber = $cookies.get('oifcOrderNumber');
        }
        if (angular.isDefined($cookies.get('oifcStartDate'))) {
            $scope.filters.StartDate = $cookies.get('oifcStartDate');
        }
        if (angular.isDefined($cookies.get('oifcEndDate'))) {
            $scope.filters.EndDate = $cookies.get('oifcEndDate');
        }
        if (angular.isDefined($cookies.get('oifcPlant'))) {
            $scope.filters.Plant = $cookies.get('oifcPlant');
        }
    };

    $scope.setInitialFilter();

    $scope.doSearch();
}]);


