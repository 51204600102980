window.$ = window.jQuery = require('jquery');

require("./libs/bootstrap.js");
require("./libs/LoadingDots.js");
require("./libs/angular.js");
require("./libs/angular-cookies.js");
require("./libs/jquery-ui.min.js");
require("./libs/ui-bootstrap-tpls-2.5.0.min.js");
require("./libs/jquery-typeahead.min.js");


$(function () {
    initAjaxPagingLinks(); // Also handles sorting now
});

// Angular app
angular.module("orderSamples", ['ui.bootstrap', 'ngCookies']);
angular.module("orders", ['ui.bootstrap', 'ngCookies']);
angular.module("searchshiptickets", ['ui.bootstrap', 'ngCookies']);
angular.module("sampleOrders", ['ui.bootstrap', 'ngCookies']);
require("./ngControllers/Orders/ordersController.js");
require("./ngControllers/Samples/sampleOrdersController.js");
require("./ngControllers/SearchShipTickets/searchShipTicketsController.js");
require("./ngControllers/Samples/orderSamplesController.js");
require("./ngControllers/Samples/orderSamplesFulfillmentsController.js");
require("./ngControllers/Samples/orderSamplesCheckoutController.js");
require("./ngControllers/Samples/orderSamplesHistoryController.js");

angular.module("orderInventory", ['ui.bootstrap', 'ngCookies']);
require("./ngControllers/Inventory/orderInventoryController.js");
require("./ngControllers/Inventory/orderInventoryFulfillmentsController.js");

var formLine = function () {
    var options = new Array(
        { text: "", val: "" },
        { text: "Arriscraft", val: "Arriscraft" },
        { text: "Block", val: "Block" },
        { text: "Brick Mfg", val: "Brick Mfg" },
        { text: "Brick Purchased", val: "Brick Purchased" },
        { text: "Concrete Masonry Unit", val: "Concrete Masonry Unit" },
        { text: "Creative Mines", val: "Creative Mines" },
        { text: "FirePlace", val: "FirePlace" },
        { text: "GG Colored Mortar", val: "GG Colored Mortar" },
        { text: "Hardscape", val: "Hardscape" },
        { text: "Imports", val: "Imports" },
        { text: "Landmark", val: "Landmark" },
        { text: "Landscape", val: "Landscape" },
        { text: "Laticrete", val: "Laticrete" },
        { text: "Manufactured Stone", val: "Manufactured Stone" },
        { text: "Masonry Access", val: "Masonry Access" },
        { text: "Misc/Resale/Other", val: "Misc/Resale/Other" },
        { text: "Natural Stone", val: "Natural Stone" },
        { text: "Terracade", val: "Terracade" },
        { text: "Thin Tech", val: "Thin Tech" },
        { text: "Tru-Brix", val: "Tru-Brix" }
    );
    var plantOptions = new Array(
        { text: "", val: "" },
        { text: "Adel", val: "Adel" },
        { text: "Bigler", val: "Bigler" },
        { text: "Caledonia", val: "Caledonia" },
        { text: "Capitol", val: "Capitol" },
        { text: "Cushwa", val: "Cushwa" },
        { text: "Hanley", val: "Hanley" },
        { text: "Iberia", val: "Iberia" },
        { text: "KF", val: "KF" },
        { text: "Landmark Stone", val: "Landmark Stone" },
        { text: "Lawrenceville", val: "Lawrenceville" },
        { text: "Marseilles", val: "Marseilles" },
        { text: "MidAtlantic", val: "Mid-Atlantic" },
        { text: "Pittsburgh", val: "Pittsburgh" },
        { text: "Redfield", val: "Redfield" },
        { text: "Rocky Ridge", val: "Rocky Ridge" },
        { text: "SGT Bluff", val: "SGT Bluff" },
        { text: "York", val: "York" }
    );
    var newSelect = $('<select/>', {
        class: "form-control prodmat prodfield",
        name: "productmaterial[]",
        value: ""
    });
    newSelect.html($.map(options, function (option) {
        return $('<option/>', {
            text: option.text,
            value: option.value,
            placeholder: "Select Product Type"
        });
    }));
    var newPlantSelect = $('<select/>', {
        class: "form-control prodmat prodfield",
        name: "productplant[]",
        value: ""
    });
    newPlantSelect.html($.map(plantOptions, function (plantOption) {
        return $('<option/>', {
            text: plantOption.text,
            value: plantOption.value,
            placeholder: "Select Plant"
        });
    }));
    var newText = $('<input/>', {
        class: "text form-control prodname prodfield",
        name: "productname[]",
        type: "text",
        placeholder: "Product Name",
        value: ""
    });
    var newQty = $('<input/>', {
        class: "text form-control prodqty prodfield",
        name: "productqty[]",
        type: "number",
        placeholder: "Quantity",
        min: "0",
        value: ""
    });
    var newRemove = $('<span/>', {
        class: 'remove',
        title: 'Remove Quantity',
        text: '-'
    });

    var lineBuilder = $('<div/>', { class: 'formLine' })
        .append(newSelect)
        .append(newPlantSelect)
        .append(newText)
        .append(newQty)
        .append(newRemove);

    return lineBuilder;
};

//Typeahead for Salesforce Forms

//States & provinces array
let stateValues = {
    "AL": "Alabama", "AK": "Alaska", "AB": "Alberta", "AZ": "Arizona", "AR": "Arkansas", "BC": "British Columbia", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "DC": "District Of Columbia", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MB": "Manitoba", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NB": "New Brunswick", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "NL": "Newfoundland and Labrador", "NC": "North Carolina", "ND": "North Dakota", "NT": "Northwest Territories", "NS": "Nova Scotia", "NU": "Nunavut", "OH": "Ohio", "OK": "Oklahoma", "ON": "Ontario", "OR": "Oregon", "PA": "Pennsylvania", "PE": "Prince Edward Island", "QC": "Quebec", "RI": "Rhode Island", "SK": "Saskatchewan", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming", "YT": "Yukon" };
//Architect and Contractor
$.each(stateValues, function (key, value) {
    $('#b68d0540-e47c-4a02-a653-a0cfb1995d15')
        .append($('<option>', { value: key })
            .text(value));
    $('#e6b89052-5ff3-4f53-c195-5a3e71e04aa1')
        .append($('<option>', { value: key })
            .text(value));
});

//add class to get styles
$('#7bdd4112-e8ca-448b-b370-b966f4447d5b').parent('div').addClass('typeahead__container');

//Architect/Design Firm
//State select change
$('#b68d0540-e47c-4a02-a653-a0cfb1995d15').change(function () {
    //clear prior results
    $('.typeahead__cancel-button_architect').remove();
    $('.typeahead__result_architect').remove();
    setArchitectTypeahead();
});

function setArchitectTypeahead() {
    $('#7bdd4112-e8ca-448b-b370-b966f4447d5b').typeahead({
        order: "asc",
        display: "Name",
        template: "{{Name}} ({{BillingCity}}, {{BillingState}})",
        templateValue: "{{Name}} ({{BillingCity}}, {{BillingState}})",
        cache: true,
        ttl: 0, //10 minutes
        generateOnLoad: true,
        source: {
            ajax: {
                url: "/Umbraco/Api/SalesforceAPI/GetPartners?partnerType=Architect;Designer&state=" + $('#b68d0540-e47c-4a02-a653-a0cfb1995d15').val(),
                path: "records",
            }
        },
        callback: {
            onInit: function (node) {
                console.log('Typeahead Initiated on ' + node.selector);
            },
            onClick: function (node, a, item, event) {
                //save to hidden field
                $('#20d208e1-10ba-41a2-8c0f-f3646e1237ac').val(item.Id);
                //console.log(item.Id);
            }
        },
        selector: {
            result: "typeahead__result_architect",
            cancelButton: "typeahead__cancel-button_architect typeahead__cancel-button"
        }
    });
}

//Contractor
//State select change
$('#e6b89052-5ff3-4f53-c195-5a3e71e04aa1').change(function () {
    //clear prior results
    $('.typeahead__cancel-button_contractor').remove();
    $('.typeahead__result_contractor').remove();
    setContractorTypeahead();
});

//add class to get styles
$('#254bdffe-91ea-4e52-9420-3c7fa29b369e').parent('div').addClass('typeahead__container');

function setContractorTypeahead() {
    $('#254bdffe-91ea-4e52-9420-3c7fa29b369e').typeahead({
        order: "asc",
        display: "Name",
        template: "{{Name}} ({{BillingCity}}, {{BillingState}})",
        templateValue: "{{Name}} ({{BillingCity}}, {{BillingState}})",
        cache: true,
        ttl: 0,
        generateOnLoad: true,
        source: {
            ajax: {
                url: "/Umbraco/Api/SalesforceAPI/GetPartners?partnerType=Contractor&state=" + $('#e6b89052-5ff3-4f53-c195-5a3e71e04aa1').val(),
                path: "records",
            }
        },
        callback: {
            onInit: function (node) {
                console.log('Typeahead Initiated on ' + node.selector);
            },
            onClick: function (node, a, item, event) {
                //save to hidden field
                $('#fdbe49fb-c41a-434b-aaa6-0b724fda63b2').val(item.Id);
                //console.log(item.Id);
            }
        },
        selector: {
            result: "typeahead__result_contractor",
            cancelButton: "typeahead__cancel-button_contractor typeahead__cancel-button"
        }
    });
}



//Ajax Table Paging
function initAjaxPagingLinks() {

    //var href = location.href; alert(href);
    //var pageNum = getParameterByName(href, "PageNum"); alert(pageNum);

    $('#clear-search').click(function () { clearAndSubmit(); return false; });

    //begin ray's code
    $(".big-target > div").click(function (e) {
        if ($(e.target).is(".document-link")) {
            return;
        }
        window.location = $(this).find("a[href]").first().attr("href");
    });

    $.fn.resizeTarget = function () {
        var maxHeight = 0;
        $(".big-target > div").css("height", "");
        $(".big-target > div").each(function (i) {

            if ($(this).outerHeight(true) > maxHeight)
                maxHeight = $(this).outerHeight(true);
        });
        $(".big-target > div").css("height", maxHeight + "px");
    };
    $.fn.resizeTarget();
    $(window).resize(function () {
        $.fn.resizeTarget();
    });

    $.fn.recalculate = function () {

        var textarea = $(this).find("textarea");
        var formLines = $('.formLine');
        var newAnswers = [];

        formLines.each(function () {
            var me = $(this);
            var mats = me.find("select[name='productmaterial[]']").val();
            var plant = me.find("select[name='productplant[]']").val();
            var name = me.find("input[type='text']").val().trim();
            var qnty = me.find("input[type='number']").val();
            var lineData = "";

            lineData += "mats:" + mats + "ω";
            lineData += "plnt:" + plant + "ω";
            lineData += "name:" + name + "ω";

            if (!lineData.includes("name:ω")) {
                qnty = qnty === "" ? "1" : qnty;
                lineData += "qnty:" + qnty;
                newAnswers.push(lineData);
            }
        });

        textarea.val(newAnswers.join("Ω"));
        if ($(this).find("input").length < 1) {
            $(this).append(formLine);
            //$(".form-group.approxqty").append("<div><input class='text form-control' name='productqty[]' type='text' value='' /></div>");
        }
    };

    $(".form-group.productnameplantapproxqty").each(function (i) {

        var me = $(this);
        var qty = $('.form-group.productnameplantapproxqty + .form-group.approxqty ');
        var label = me.find("label");
        var textarea = me.find("textarea");

        //changed to omegas because of use of comma and semicolon in GG products
        var answers = textarea.val().split("Ω");
        for (var ii = 0; ii < answers.length; ii++) {
            var answSplitter = answers[ii].split("ω");
            if (answSplitter[1]) {
                for (var as = 0; as < 3; as++) {
                    answSplitter[as] = answSplitter[as].substring(5);
                }

                if (!answSplitter[2]) {
                    answSplitter[2] = '';
                }
            }
            //console.log(answSplitter);
            // me.append("<div><input class='text form-control prodname' name='productname[]' type='text' value='" + answSplitter[0] + "' /><input class='text form-control' name='productqty[]' type='number' value='" + answSplitter[1] + "' /></div>"); // dropped remove from initial build
            me.append(formLine);
            $('.formLine:first span').remove();
        }
        label.append(" <span title='Add a Quantity' class='add'>+</span>");
        var add = label.find(".add");
        add.click(function (e) {
            var parent = $(this).parent().parent();
            parent.append(formLine);
        });
        me.delegate(".remove", "click", function (e) {
            var parent = $(this).parent().parent();
            var count = $(this).parent().prevAll().length;
            //console.log(count);
            //console.log($('.form-group.productnameplantapproxqty div').eq(count));
            $(this).remove();
            $('.form-group.productnameplantapproxqty div').eq(count - 3).remove();
            parent.recalculate();
        });
        me.delegate("input", "blur focus keyup mouseup", function (e) {
            $(this).parent().parent().recalculate();
        });
        me.delegate("select", "blur focus keyup mouseup", function (e) {
            $(this).parent().parent().recalculate();
        });
    });
    //end ray's code


    $(".pagination li").not(".disabled").each(function () {
        var anchor = $(this).find("a")[0];

        if (anchor) {
            // Set SortDirection on page links
            var ahref = $(anchor).attr('href');
            var dir = getParameterByName(location.href, "SortDirection"); // SortDirection from current url
            ahref = updateQueryStringParameter(ahref, "SortDirection", dir);

            // Set SearchText on page links
            var search = getParameterByName(location.href, "SearchText"); // SearchText from current url
            ahref = updateQueryStringParameter(ahref, "SearchText", search);

            $(anchor).attr('data-url', ahref);
            $(anchor).attr('href', 'javascript:void(0)');
            initAjaxPaging(anchor);
        }
    });

    // For sorting
    $("#resultstable th").each(function () {
        var anchor = $(this).find("a")[0];

        if (anchor) {
            // Set SortDirection and PageNum on sort links
            var ahref = $(anchor).attr('href');
            var dir = getParameterByName(location.href, "SortDirection"); // SortDirection from current url
            ahref = updateQueryStringParameter(ahref, "SortDirection", dir);
            var p = getParameterByName(location.href, "PageNum"); // PageNum from current url
            ahref = updateQueryStringParameter(ahref, "PageNum", p);
            var search = getParameterByName(location.href, "SearchText"); // SearchText from current url
            ahref = updateQueryStringParameter(ahref, "SearchText", search);

            $(anchor).attr('data-url', ahref);
            $(anchor).attr('href', 'javascript:void(0)');
            initAjaxPaging(anchor);
        }
    });

    $(".account.dropdown select").each(function () {
        $.ajax({
            'url': '/SampleOrdering/Admin/API/getAccounts',
            'type': 'GET',
            'success': function (result) {
                //console.log(result);
                for (var i = 0; i < result.length; i++) {
                    var r = result[i];
                    $('.account.dropdown select')
                        .append($("<option>")
                            .attr("value", r.customerNumber)
                            .text(r.customerNumber.toString() + " " + r.customerName))
                }
            }
        });
    });
}

function initAjaxPaging(a) {

    $(a).click(function (e) {
        e.preventDefault();

        var replaceSel = "#results"; //= $(this).data('ggpajaxtarget');


        var ajaxUrl = $(this).data("url");
        var p = getParameterByName(ajaxUrl, "PageNum");
        var sortField = getParameterByName(ajaxUrl, "SortField");
        var sortDirection = getParameterByName(ajaxUrl, "SortDirection");
        var searchText = getParameterByName(ajaxUrl, "SearchText");

        var thisid = "paging";
        var source = $(this).data("source");
        if (source == "sort") {
            if (sortDirection == "d") {
                sortDirection = "a";
            }
            else {
                sortDirection = "d"; // Default
            }
            ajaxUrl = updateQueryStringParameter(ajaxUrl, "SortDirection", sortDirection);
            thisid = $(this).attr("id");
        }

        $(replaceSel).Loadingdotdotdot({
            "speed": 500,
            "maxDots": 20,
            "word": "Fetching additional records"
        });

        $.ajax({
            'url': ajaxUrl,
            'type': 'GET',
            'success': function (result) {
                $(replaceSel).Loadingdotdotdot("Stop");

                var lhref = location.href;
                //var qs = "PageNum=" + p;

                // lhref += (lhref.match(/\?/) ? '&' : '?') + qs;

                lhref = updateQueryStringParameter(lhref, "PageNum", p);
                lhref = updateQueryStringParameter(lhref, "SortField", sortField);
                lhref = updateQueryStringParameter(lhref, "SortDirection", sortDirection);
                history.pushState(null, null, lhref);

                $(replaceSel).replaceWith(result);

                // Replace sortdirection if sort link was clicked
                if (thisid != "paging") {
                    $("#" + thisid).attr("href", ajaxUrl);
                }
                // Update PDF links
                var pdfLinkUrl = $("#OrdersPDF").attr("href");
                if (sortField.length > 0) { pdfLinkUrl = updateQueryStringParameter(pdfLinkUrl, "SortField", sortField); }
                if (sortDirection.length > 0) { pdfLinkUrl = updateQueryStringParameter(pdfLinkUrl, "SortDirection", sortDirection); }
                if (searchText.length > 0) { pdfLinkUrl = updateQueryStringParameter(pdfLinkUrl, "SearchText", searchText); }
                $("#OrdersPDF").attr("href", pdfLinkUrl);

                var pdfDetailsLinkUrl = $("#OrdersPDFDetails").attr("href");
                if (sortField.length > 0) { pdfDetailsLinkUrl = updateQueryStringParameter(pdfDetailsLinkUrl, "SortField", sortField); }
                if (sortDirection.length > 0) { pdfDetailsLinkUrl = updateQueryStringParameter(pdfDetailsLinkUrl, "SortDirection", sortDirection); }
                if (searchText.length > 0) { pdfDetailsLinkUrl = updateQueryStringParameter(pdfDetailsLinkUrl, "SearchText", searchText); }
                $("#OrdersPDFDetails").attr("href", pdfDetailsLinkUrl);

                $('#resultstable th').removeClass('sort');
                $('#resultstable th').removeClass('sortasc');
                $('#resultstable th').removeClass('sortdesc');
                var sortHeader = $('#resultstable th#hdr_' + sortField);
                sortHeader.addClass('sort');
                if (sortDirection == 'a') {
                    sortHeader.addClass('sortasc');
                } else {
                    sortHeader.addClass('sortdesc');
                }
                initAjaxPagingLinks();
            }
        });

    });
}

function clearAndSubmit() {
    $('#search-orders').val('');
    // replace SearchText in query string with nothing so all orders show
    var ahref = location.href;
    ahref = updateQueryStringParameter(ahref, "SearchText", "");
    location.href = ahref;
}

// SM Added
if ($(".field-validation-error").length > 0) {
    $('html, body').animate({ scrollTop: $(".field-validation-error").offset().top }, 1000);
}

// Scroll to error message on the forms
$(':submit[name="submitbtn"]').click(function () {
    if ($(".field-validation-error").length > 0) {
        $('html, body').animate({ scrollTop: $(".field-validation-error").offset().top - 100 }, 1000);
    }
});


function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}
function getParameterByName(url, name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

//Project Registration Request (Job Protection) Form
//$('#6f43478f-4d73-4e65-c5d2-ee2f982a93ae_1').on('input', function () {
//    var shipDate = new Date($(this).val());
//    var now = new Date().setHours(0,0,0,0);
//    var errorSpan = $('<span/>', {
//        class: 'field-validation-error backup',
//        text: 'Please enter a future date in the format mm/dd/yyyy'
//    });
//    $('.field-validation-error.backup').remove();
//    console.log(now);
//    console.log(shipDate);

//    if ($(this).val().length === 10 && shipDate < now && $('.anticipatedtimematerialsneeded .backup').length === 0) {
//        $('.anticipatedtimematerialsneeded').append(errorSpan);
//    } else if ($(this).val().length === 10) {
//        $('.field-validation-error.backup').remove();
//    }
//});

//$("#6f43478f-4d73-4e65-c5d2-ee2f982a93ae_1").prop("readonly", true); //set Anticipated Time Materials Needed date to readonly so user can't type bad dates //This allows form to submit if nothing entered. Fixed by adding rule to FieldType.DatePicker.cshtml