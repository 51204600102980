﻿var cartNameArray = true;

angular.module("orderSamples").controller("orderSamplesController", ["$scope", "$http", "$cookies", "$window", function ($scope, $http, $cookies, $window) {
    
    $scope.cartItemsArray = $window.cartNameArray;

    $scope.samples = [];
    $scope.numSamples = 0;

    $scope.sizeOptions = ["Select"];
    $scope.colorOptions = ["Select"];
    $scope.seriesOptions = ["Select"];
    $scope.plantOptions = ["Select"];
    $scope.AccountAddresses = [];
    $scope.nameSuggestions = [];
    $scope.numberSuggestions = [];

    $scope.cartValue = "";

    $scope.totalPages = 1;

    $scope.filters = {
        ProductName: '',
        Size: '',
        Color: '',
        Plant: '',
        Category: '',
        Type: '',
        Series: '',
        PageSize: 30,
        PageNumber: 1,
        ProductName:''
    };
    
    $scope.encodeAll = function (input) {
        return btoa(input);
    }

    
    $scope.findInCart = function (sampleName, index) {
        sampleName = sampleName.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
        sampleName = sampleName.replace("quot", "").toLowerCase();
        sampleName = sampleName.replace(" ", "").toLowerCase();
        
        if ($scope.cartItemsArray.indexOf(sampleName) != -1) {
            return true;
        }
        else {
            return false;
        }
        
        return false;
    };


    $scope.doSearch = function (preservePage) {
        // Clear any suggestions lists
        $scope.nameSuggestions = [];
        $scope.numberSuggestions = [];
        $scope.loading = true;

        // Reset to first page as needed
        if (!preservePage) $scope.filters.PageNumber = 1;

        $http.get('/SampleOrdering/GetSamples', {
            params: $scope.filters
        }).then(function (result) {
            $scope.loading = false;
            $scope.plantOptions = result.data.Plants;
            $scope.sizeOptions = result.data.Sizes;
            $scope.colorOptions = result.data.Colors;
            $scope.seriesOptions = result.data.Series;
            $scope.categoryOptions = result.data.Categories;
            $scope.typeOptions = result.data.Types;
            $scope.samples = result.data.SampleList;
            $scope.totalPages = result.data.TotalPages;

            $scope.numSamples = $scope.samples.length;

            if (result.data.SampleList.length == 0) {
                $scope.filters = {
                    ProductName: '',
                    Size: '',
                    Color: '',
                    Plant: '',
                    Category: '',
                    Type: '',
                    Series: '',
                    PageSize: 30,
                    PageNumber: 1,
                    ProductName: ''
                };
                $cookies.put('oscCategory', $scope.filters.Category);
                $cookies.put('oscType', $scope.filters.Type);
                $cookies.put('oscPlant', $scope.filters.Plant);
                $cookies.put('oscColor', $scope.filters.Color);
                $cookies.put('oscProductName', $scope.filters.ProductName);
                $cookies.put('oscSize', $scope.filters.Size);
                $cookies.put('oscSeries', $scope.filters.Series);
                $scope.doSearch();
            }
            });


        $cookies.put('oscCategory', $scope.filters.Category);
        $cookies.put('oscType', $scope.filters.Type);
        $cookies.put('oscPlant', $scope.filters.Plant);
        $cookies.put('oscColor', $scope.filters.Color);
        $cookies.put('oscProductName', $scope.filters.ProductName);
        $cookies.put('oscSize', $scope.filters.Size);
        $cookies.put('oscSeries', $scope.filters.Series);
    };

    $scope.getNameSuggestions = function (kw) {
        if (kw.length > 1) {

            $scope.filters.ProductName = kw;
            $http.get('/SampleOrdering/GetNameSuggestions', {
                params: $scope.filters,
                cache: true
            }).then(function (response) {
                $scope.nameSuggestions = response.data;
            });
        }
    };

    $scope.getNumberSuggestions = function (kw) {
        if (kw.length > 1) {
            $http.get('/SampleOrdering/GetNumberSuggestions', {
                params: { keyword: kw },
                cache: true
            }).then(function (response) {
                $scope.numberSuggestions = response.data;
            });
        }
    };

    $scope.changePage = function (selectedPage) {
        $scope.filters.PageNumber = selectedPage;
        $scope.doSearch(true);
        return false;
    };

    $scope.pageArray = function (numPages, currentPage) {

        var pages = [];
        var ellipsisAdded = false;
        for (var i = 1; i <= numPages; i++) {
            if (i == 1 || i == numPages)
                // Always show the first and last pages
                pages.push(i.toString())
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(i.toString());
                ellipsisAdded = false;
            }
            else if (!ellipsisAdded) {
                pages.push('...');
                ellipsisAdded = true;
            }

        }

        return pages;
    }

    $scope.clearSearch = function () {
        $scope.filters = {
            ProductName: '',
            ProductNumber: '',
            Size: '',
            Color: '',
            Series: '',
            Plant: '',
            PageSize: 30,
            PageNumber: 1
        };

        $scope.doSearch();
    };

    $scope.setInitialFilter = function () {

        if (angular.isDefined($cookies.get('oscCategory'))) {
            $scope.filters.Category = $cookies.get('oscCategory');
        }
        if (angular.isDefined($cookies.get('oscType'))) {
            $scope.filters.Type = $cookies.get('oscType');
        }
        if (angular.isDefined($cookies.get('oscPlant'))) {
            $scope.filters.Plant = $cookies.get('oscPlant');
        }
        if (angular.isDefined($cookies.get('oscColor'))) {
            $scope.filters.Color = $cookies.get('oscColor');
        }
        if (angular.isDefined($cookies.get('oscProductName'))) {
            $scope.filters.ProductName = $cookies.get('oscProductName');
        }
        if (angular.isDefined($cookies.get('oscSize'))) {
            $scope.filters.Size = $cookies.get('oscSize');
        }
        if (angular.isDefined($cookies.get('oscSeries'))) {
            $scope.filters.Series = $cookies.get('oscSeries');
        }
    };

    $scope.setInitialFilter();
    $scope.doSearch();

    $scope.addtoCart = function (productId) {
        window.addtoCart(productId);
    };

    $scope.changeAccount= function (){

        var customData = { EntityId: entityId, UserName: '@u.UserName' };

    }

}]);